<template>
    <div>
        <the-previewer :event-bus="eventBus" @change="onChangeDesign" @capture="addApprovedDesignToPrototypeMoodboard">
            <template #actionsLeft>
                <v-btn v-if="$inGroupArchitects() && !$isStepApproved(PROJECT_DESIGN)" :disabled="!selectedDesign && !$isStepReleased(PROJECT_DESIGN)" class="mr-5 boxed-icon" color="primary" tile @click="approveSelectedDesign" :loading="isApproving">
                    {{ $t('workflow_design.approve') }}
                    <v-icon right>mdi-check</v-icon>
                </v-btn>
            </template>
        </the-previewer>

    </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import ThePreviewer from '@/components/ThePreviewer';
import { getEditableIndicatorBase, progressWorkflow, updateFirestoreWorkflowSelectedDesign } from '@/firebase/firestore/workflows';
import { uploadFilesAndAddFirestoreMoodboardEntry } from '@/firebase/firestore/projects/moodboards';
import { newId } from '@/firebase';
import { updateFirestoreDesign } from '@/firebase/firestore/projects/designs';

export default {
    name: 'TheProjectDesigner',

    components: {ThePreviewer},

    data: () => ({
        selectedDesign: null,
        eventBus: new Vue(),
        isApproving: false,
    }),

    computed: {
        ...mapGetters('authentication', {
            user: 'getUser',
        }),
        ...mapGetters('project', {
            prototype: 'proto',
        }),
        ...mapState('workflow', {
            workflow: 'boundInstance',
        }),
    },

    methods: {
        onChangeDesign (design) {
            this.selectedDesign = design;
        },

        async approveSelectedDesign () {
            this.isApproving = true;

            // Eerst een screenshot maken en met dat screenshot de geodkeuring starten
            this.eventBus.$emit('capture', {
                data: this.data
            });

            await updateFirestoreWorkflowSelectedDesign(this.workflow, this.selectedDesign.$snap.ref)
                .catch(error => console.log('Error during update of selected design in workflow: ' + error));
            await updateFirestoreDesign(this.selectedDesign.$snap.ref, {deletable: false})
                .catch(error => console.log('Error during making selected design undeletable: ' + error));
        },

        async addApprovedDesignToPrototypeMoodboard (data) {
            const files = [data.image];
            files[0].name = newId().substr(-7) + '-approved.png';

            await uploadFilesAndAddFirestoreMoodboardEntry(this.prototype.$ref, {
                heading: this.$t('moodboard.label_approved_design'),
                comment: '“' + this.selectedDesign.name + '”',
                design: this.selectedDesign.$snap.ref,
                files,
                user: this.user,
            }).catch(error => console.log('Error while adding moodboard entry to prototype fase: ' + error));

            // TODO: deze aanroep hoort in approveStep(), maar mogelijk verstoort dit upload van design-screenshot
            return this.$approveStep(this.PROJECT_DESIGN)
                .then(() => {
                    this.isApproving = false;

                    // Kijk of de stap al beschikbaar is (heet editable in het systeem)
                    if (!getEditableIndicatorBase(this.workflow, this.PROJECT_PROTOTYPE)) {
                        progressWorkflow(this.workflow, this.PROJECT_DESIGN);
                    }
                })
                .catch(error => console.log('Error during approval current step design: ' + error))
                .finally(() => this.$store.dispatch('workflow/gotoStep', this.PROJECT_PROTOTYPE));
        },
    },
};
</script>

<style scoped>

</style>
